<template>
  <div class="body">
    <div class="banxin">
      <div class="tt df aic">
        <div class="suxian"></div>
        贷款基本信息
      </div>
      <div class="bghui">
        <div class="OrderDetail_mid">
          <div class="list">
            <div class="li fs14">
              <div class="fhui">贷款号</div>
              <div class="fs14">{{ LoanInfo.loanId }}</div>
            </div>
            <div class="li fs14">
              <div class="fhui">申请时间</div>
              <div>{{ LoanInfo.addTime }}</div>
            </div>
            <div class="li fs14">
              <div class="fhui">贷款人姓名</div>
              <div>{{ LoanInfo.custName }}</div>
            </div>
          </div>
          <div class="su"></div>
          <div class="seacend list">
            <div class="li fs14">
              <div class="fhui">贷款金额</div>
              <div class="fs14">{{ LoanInfo.appLoanAmt }}</div>
            </div>
            <div class="li fs14">
              <div class="fhui">贷款状态</div>
              <div style="color: #ffa936">
                {{ LoanInfo.state == '1' ? "还款中" : "已结束" }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tt df aic">
        <div class="suxian"></div>
        还款信息
      </div>
      <div class="table">
        <el-table
          :header-cell-style="{ background: '#F8F8F8' }"
          ref="singleTable"
          :data="tableData"
          highlight-current-row
          style="width: 100%"
        >
          <el-table-column
            type="index"
            label="序号"
            align="center"
            width="80"
          ></el-table-column>
          <el-table-column
            v-if="LoanInfo.state == '1' "
            property="due_date"
            align="center"
            label="到期时间"
            width="auto"
          ></el-table-column>
          <el-table-column
            v-if="LoanInfo.state !== '1' "
            property="settleDate"
            align="center"
            label="处理时间"
            width="auto"
          ></el-table-column>
          <el-table-column
            v-if="LoanInfo.state == '1' "
            property="index"
            align="center"
            label="期数"
            width="auto"
          >
            <template slot-scope="scope">
              {{ scope.row.Now_amount }}/{{ scope.row.total_amount }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="LoanInfo.state == '1' "
            property="due_pri_amt"
            align="center"
            label="本期本金"
            width="auto"
          ></el-table-column>
                 <el-table-column
            v-if="LoanInfo.state !== '1' "
            property="prePriAmt"
            align="center"
            label="还款本金"
            width="auto"
          ></el-table-column>
          <el-table-column
              v-if="LoanInfo.state == '1' "
            property="due_int_amt"
            align="center"
            label="本期利息"
            width="auto"
          ></el-table-column>
          <el-table-column
              v-if="LoanInfo.state !== '1' "
            property="preIntAmt"
            align="center"
            label="利息金额"
            width="auto"
          ></el-table-column>
          <el-table-column
             v-if="LoanInfo.state == '1' "
            property="def_inter"
            align="center"
            label="罚息"
            width="auto"
          ></el-table-column>
          <el-table-column
             v-if="LoanInfo.state !== '1' "
            property="preOdpAmt"
            align="center"
            label="罚息"
            width="auto"
          ></el-table-column>
          <el-table-column
                v-if="LoanInfo.state == '1' "
            property="comp_inter"
            align="center"
            label="复利"
            width="auto"
          ></el-table-column>
          <el-table-column
                v-if="LoanInfo.state !== '1' "
            property="preOdiAmt"
            align="center"
            label="复利"
            width="auto"
          ></el-table-column>
        </el-table>
        <div v-if="this.LoanInfo.state == '1'" class="footerConfirm">
          <el-button @click="goRepayment" type="success"
            >发起还款信息</el-button
          >

          <el-button @click="confrimPay">确认还款信息</el-button>
        </div>
      </div>
    </div>

    <!-- 发起还款信息 -->
    <div class="dia">
      <el-dialog
        title="确认还款信息"
        :visible.sync="ConfirmPay"
        width="20%"
        :before-close="handleClose"
      >
        <div class="toast">
          <div style="color: #faad14; margin-right: 8px" class="icon">
            <i class="el-icon-warning"></i>
          </div>
          确认还款信息，点击下方“还款”按钮将跳转到银行 还款界面。
        </div>
        <div class="sureli">
          <div class="fhui fs14">贷款总额</div>
          <div class="rt">¥ {{ fastPay.prePriAmt }}</div>
        </div>
        <div class="sureli">
          <div class="fhui fs14">需还本金</div>
          <div class="rt">¥ {{ fastPay.prePriAmt }}</div>
        </div>
        <div class="sureli">
          <div class="fhui fs14">需还利息</div>
          <div class="rt">¥ {{ fastPay.preIntAmt }}</div>
        </div>
        <div class="sureli">
          <div class="fhui fs14">需还罚息</div>
          <div class="rt">¥ {{ fastPay.preOdpAmt }}</div>
        </div>
        <div class="sureli">
          <div class="fhui fs14">还款总金额</div>
          <div class="rt fred">¥ {{ fastPay.preTotalAmt }}</div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="ConfirmPay = false"
            >取 消</el-button
          >
          <el-button type="success" @click="goPay">确认还款</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 提前还款信息 -->
    <!-- <div class="dia prepayment">
      <el-dialog
        title="提交利息试算请求"
        :visible.sync="prepayment"
        width="20%"
        :before-close="handleClose"
      >
        <el-form ref="form" :model="form" label-width="120px">
          <el-form-item
            :rules="[
              { required: true, message: '请选择还款类型', trigger: 'blur' },
            ]"
            label="还款类型"
          >
            <el-select v-model="prepaymentValue" placeholder="请选择还款类型">
              <el-option label="提前结清" value="PF"></el-option>
              <el-option label="提前部分还款" value="PP"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            :rules="[
              {
                required: true,
                message: '请输入部分还款金额',
                trigger: 'blur',
              },
            ]"
            label="部分还款金额"
          >
            <el-input
              placeholder="请输入部分还款金额"
              v-model="prepaymentPrice"
            ></el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="prepayment = false"
            >取 消</el-button
          >
          <el-button type="success" @click="interestment">确认还款</el-button>
        </span>
      </el-dialog>
    </div> -->

    <!-- 确认还款信息 -->
    <el-dialog :visible.sync="huankuan" width="390px" title="提示">
      <div style="padding: 30px" class="info">
        <div class="df">
          <div class="icon">
            <i style="line-height: 30px" class="el-icon-warning"></i>
          </div>
          <div style="line-height: 2">
            请求已发送,请等待几分钟再点击
            <span class="gr">"确认还款信息"</span>按钮
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="huankuan = false">取 消</el-button>
        <el-button type="success" @click="huankuan = false">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
 
<script>
import {
  goRepay,
  getLoanInfoByLoanId,
  interest,
  getPrepayInfo,
  collectload,
} from "@/request/Loan/Loan";
export default {
  data() {
    return {
      fastPay: {},
      //提前还款金额
      prepaymentPrice: "",
      //提前还款vlue
      prepaymentValue: "",
      options: [
        { label: "提前结清", value: "PF" },
        { label: "提前部分还款", value: "PP" },
      ],
      LoanInfo: {},
      huankuan: false,
      prepayment: false,
      ConfirmPay: false,
      tableData: [],
      active: 1,
      //确认还款URL
      payurl: "",
    };
  },
    watch: {
    $route: {
      immediate: true,
      handler(newVal, olVal) {
        if (newVal.params.orderNo) {
       this.LoanInfo = this.$route.params;
          localStorage.setItem("orderNo", newVal.params.orderNo);
          localStorage.setItem("loanId", newVal.params.loanId);
        } else {
          this.LoanInfo.orderNo = localStorage.getItem("orderNo");
          this.LoanInfo.loanId = localStorage.getItem("loanId");
        }
      },
    },
  },
    beforeDestroy() {
    localStorage.removeItem("orderNo");
    localStorage.removeItem("loanId");
  },
  created() {
    this.getList();
  },

  methods: {
    goPay() {
      this.ConfirmPay = false;
      goRepay({ loanId: this.LoanInfo.loanId }).then((res) => {
        if (res.code === 200) {
          this.payurl = res.data;
           window.location.href = window.open(res.data)
        }
      });
    },
    //确认还款信息
    confrimPay() {
      getPrepayInfo({ loanId: this.LoanInfo.loanId  }).then((res) => {
        if (res.code === 200) {
          this.fastPay = res.data.data;
          this.ConfirmPay = true;
        }
      });
    },
    //发起提前还款
    goRepayment() {
      let data = {
        loanId: this.LoanInfo.loanId ,
        receiptType: "PF",
        priAmt: this.LoanInfo.appLoanAmt,
      };
      interest(data).then((res) => {
        if (res.code === 200) {
          this.huankuan = true;
        }
      });
    },
    interestment() {
      this.prepayment = false;
    },
    getList() {
      if (this.LoanInfo.state == '1') {
        getLoanInfoByLoanId({ loanId: this.LoanInfo.loanId }).then(
          (res) => {
            if (res.code === 200) {
              res.data.map((item, index) => {
                item.Now_amount = (index + 1).toString();
                item.Now_amount = item.Now_amount.padStart(2, "0");
              });
              this.tableData = res.data;
            } else {
              this.$router.go(-1);
            }
          }
        );
      } else {
        collectload({ orderNo: this.LoanInfo.orderNo }).then((res) => {
          if (res.code === 200) {
            this.tableData = res.data;
          } else {
            this.$router.go(-1);
          }
        });
      }
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>
 
<style lang = "less" scoped>
@import "../../../assets/base.less";
::v-deep {
  .el-dialog__body {
    padding: 0;
  }
}
.back{
  height: 48px;
  line-height: 48px;
  color: #707070;
}
.cup:hover{
  color: @green;
}
.info {
  display: flex;
  align-items: center;
  .icon {
    font-size: 19px;
    color: #faad14;
    margin-right: 10px;
  }
}
.toast {
  margin-top: 15px;
  background-color: #fffae5;
  padding: 30px 15px;
  display: flex;
  line-height: 1.5;
}

.fhui {
  color: #707070;
}
.rt {
  color: #333333;
}

::v-deep {
  .el-input__inner {
    width: 212px;
    height: 36px;
    line-height: 36px;
  }
  .dia {
    .el-dialog__title::before {
      width: 2px;
      height: 18px;
      background: #26c487;
      border-radius: 2px;
      opacity: 1;
      margin-right: 5px;
      /* margin: 0 5px 0 0;
      background: #2cc389;
      border: 2px solid #2cc389;
      display: inline-block;
      vertical-align: middle;
      border-left: 2px solid #2cc389;
      content: "";
      height: 16px;
      line-height: 12px; */
    }
  }
}
.prepayment {
  .li {
    display: flex;
    align-items: center;
    .txt {
      text-align: right;
      width: 30%;
      margin-right: 10px;
    }
  }
}
.red {
  color: #e33233;
}
.footerConfirm {
  box-sizing: border-box;
  width: 100%;
  background: #fff;
  padding-bottom: 52px;
  padding-right: 30px;
  padding-top: 30px;
  display: flex;
  justify-content: flex-end;
}
.suxian {
  margin-right: 8px;
  font-size: 18px;
  font-weight: 600;
  color: #333333;
}
.sureli {
  padding: 0 30px;
  display: flex;
  height: 46px;
  line-height: 46px;
  justify-content: space-between;
  border-bottom: 1px dashed @hui;
  background: linear-gradient(white, white) padding-box,
    repeating-linear-gradient(-45deg, #ccc 0, #ccc 0.5em, white 0, white 0.75em);
}
.OrderDetail_mid {
  justify-content: space-around;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 30px;
  background: #f8f8f8;
  height: 168px;
  width: 100%;

  .su {
    margin-top: -10px;
    height: 90px;
    border: 1px solid #e7ebe7;
    display: inline-block;
    vertical-align: middle;
    border-left: 1px solid @hui;
    content: "";
  }
  .list {
    width: 45%;
  }
  .seacend {
    margin-left: 80px;
  }
  .li {
    display: flex;
    margin-bottom: 20px;
    .fhui {
      width: 25%;
    }
  }
  .h1 {
    margin-bottom: 26px;
    font-size: 14px;
    font-weight: bold;
  }
}
.body {
  background-color: @body;
}

.bghui {
  height: 150px;
  box-sizing: border-box;
  padding: 0 60px 30px 30px;
  background-color: #fff;
  box-sizing: border-box;
  color: #333333;
}

.tt {
  font-size: 18px;
  font-family: 'medium';
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  background-color: #fff;
  padding: 30px;
  font-weight: 600;
  margin-bottom: 0;
}
.table {
  padding: 0 60px 30px 30px;
  background-color: #ffffff;
}
</style>